import styled from 'styled-components';
import Box from './base/Box';

const Content = styled(Box)`
  & figure {
    width: ${props => (props.width ? props.width : '100%')};
    margin: 0;
  }

  & iframe {
    width: ${props => (props.width ? props.width : '100%')};
  }

  & img {
    width: ${props => (props.width ? props.width : '100%')};
  }
`;

export default Content;
