import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { StyledAnchor } from './base/A';
import Icon from './Icon';
import Box from './base/Box';
import Flex from './base/Flex';
import List from './base/List';
import Typography from './base/Typography';

const Item = styled(Flex)`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};

  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondaryLight};
  }
`;

const Container = styled.div.attrs(({ subPages, index }) => ({
  mb:
    index === subPages.length - 1
      ? ['-43px', null, null, '-16px']
      : ['10px', null, null, '25px'],
  pl: ['15px', null, null, '10px'],
  pr: '15px',
  height: ['60px', null, null, '44px'],
}))`
  ${space};
`;

const ListItem = ({
  subPage,
  subPages,
  index,
  parentPageSlug,
  isOpen = true,
}) => (
  <Container index={index} subPages={subPages}>
    <Item
      isOpen={isOpen}
      activeClassName="active"
      partiallyActive={true}
      as={StyledAnchor}
      to={`/${parentPageSlug}/${subPage.slug}`}
      color="primary"
      fontSize={3}
      fontWeight="semibold"
      bg="background"
      boxShadow={1}
      borderRadius="6px"
      justifyContent={['space-between', null, null, 'flex-start']}
      alignItems="center"
    >
      <Icon name="chevron_left" size="20px" mr="10px" ml="20px" />
      <Typography display="inline-block" margin="0px" padding="10px">
        {subPage.title}
      </Typography>
    </Item>
  </Container>
);

const SideNavigation = ({ subpages, pageSlug }) => {
  const [open, toggleOpen] = useState(false);

  useLayoutEffect(() => {
    function updateWidth() {
      if (window.innerWidth > 991) {
        toggleOpen(true);
      }
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const mapSubPagesToNavItems = subPages => {
    return subPages.map((subPage, index) => (
      <ListItem
        key={index}
        isOpen={open}
        subPage={subPage}
        subPages={subPages}
        index={index}
        parentPageSlug={pageSlug}
      />
    ));
  };

  return (
    <Box width={[1, null, null, 1 / 3]} mt="-20px" mb="25px">
      <Box
        bg={['transparent', null, null, 'secondaryOpaque']}
        pt="40px"
        pb={['4px', null, null, 0]}
        pr={[0, null, null, '53px', '70px']}
        pl={[0, null, null, '0']}
        float={[null, null, null, 'right']}
        position="sticky"
        top="77px"
      >
        <List as="nav" p="0" ml={['0', null, null, '-50px']} mb={4}>
          <Flex
            style={{ cursor: 'pointer' }}
            display={['block', null, null, 'none']}
            width="100%"
            color="background"
            bg="secondary"
            fontSize={3}
            fontWeight="semibold"
            height={['60px', null, null, '44px']}
            boxShadow={1}
            borderRadius="6px"
            mb="10px"
            px="15px"
            justifyContent={['space-between', null, null, 'flex-end']}
            alignItems="center"
            onClick={() => toggleOpen(!open)}
          >
            <Typography display="inline-block" margin="0px">
              {open ? 'Close' : 'Open'} Menu
            </Typography>
            <Icon name={open ? 'close' : 'menu'} size="18px" ml="30px" />
          </Flex>
          {mapSubPagesToNavItems(subpages)}
        </List>
      </Box>
    </Box>
  );
};

export default SideNavigation;
