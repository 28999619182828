import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Flex from '../components/styled/base/Flex';
import Box from '../components/styled/base/Box';
import Content from '../components/styled/Content';
import SideNavigation from '../components/styled/SideNavigation';

const Media = styled(Box)`
  & img {
    object-fit: cover;
    height: 400px;
    width: 100%;
  }

  & video {
    margin: 16px 0;
  }
`;

const SubPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.subpage.title} />
      <Flex
        position="sticky"
        top="92px"
        flexDirection={['column', null, null, 'row-reverse']}
        justifyContent="space-between"
        mb="5"
      >
        <SideNavigation
          subpages={data.subpage.page[0].subpages}
          pageSlug={data.subpage.page[0].slug}
        />
        <Flex width={[1, null, null, '60%']} flexDirection="column">
          {data.subpage.image &&
            (data.subpage.image.fluid ? (
              <Media
                width="full"
                py="3"
                textAlign={['center', null, null, 'left']}
              >
                <img
                  src={data.subpage.image.fluid.src}
                  alt={data.subpage.image.title}
                />
              </Media>
            ) : (
              <Media>
                <video controls width="100%" height="400px">
                  <source src={data.subpage.image.file.url} />
                  <track kind="captions" src="" srcLang="en" />
                  Sorry, je browser ondersteunt geen embedded videos.
                </video>
              </Media>
            ))}
          <Content
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(data.subpage.content.json),
            }}
          />
        </Flex>
      </Flex>
    </Layout>
  );
};

export default SubPage;

export const pageQuery = graphql`
  query($id: String!) {
    subpage: contentfulSubpage(id: { eq: $id }) {
      slug
      title
      page: header {
        slug
        subpages {
          title
          slug
        }
      }
      image {
        title
        fluid(cropFocus: CENTER, quality: 100, maxHeight: 800, maxWidth: 600) {
          src
        }
        file {
          url
        }
      }
      content {
        json
      }
    }
  }
`;
